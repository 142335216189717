import * as React from 'react';
import { __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { rem } from 'polished';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';
import { authProtected } from '../../utilities/auth';
import { Sidebar, FlexRow, MetaTags, FlexCol } from 'eshop-defaults';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { withRouter, WithRouterProps } from 'react-router';
import { fetchCart, cartIdSelector } from '../Cart/cartSlice';

interface Props {
  user: any;
  dispatch: any;
  children: any;
  cartId: string;
}

class MyAccount extends React.Component<Props & WithRouterProps> {
  public render() {
    const { user, children } = this.props;
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: 'Moj účet' }} />
          {this.renderSidebar()}
          <ContentWrapper>{user ? children : null}</ContentWrapper>
        </Wrapper>
        <InfoBanner />
      </>
    );
  }

  public renderSidebar = () => {
    const {
      location: { pathname },
    } = this.props;
    const items = [
      {
        name: 'Moje objednávky',
        url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
        active:
          pathname.includes(
            __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
          ) || pathname === __r('routes:moj-ucet', '/moj-ucet'),
      },
      {
        name: 'Moje faktúry a dobropisy',
        url: __r('routes:moj-ucet/faktury', '/moj-ucet/faktury'),
        active: pathname.includes(
          __r('routes:moj-ucet/faktury', '/moj-ucet/faktury'),
        ),
      },
      {
        name: 'Moje dopyty',
        url: __r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty'),
        active: pathname.includes(
          __r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty'),
        ),
      },
      {
        name: 'Nedodaný tovar',
        url: __r('routes:moj-ucet/nedodany-tovar', '/moj-ucet/nedodany-tovar'),
        active: pathname.includes(
          __r('routes:moj-ucet/nedodany-tovar', '/moj-ucet/nedodany-tovar'),
        ),
      },
      {
        name: 'Moje údaje',
        url: __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
        active:
          pathname.includes(
            __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
          ) ||
          pathname.includes(
            __r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla'),
          ),
      },
      {
        name: 'Moje dodacie adresy',
        url: __r(
          'routes:moj-ucet/moje-dodacie-adresy',
          '/moj-ucet/moje-dodacie-adresy',
        ),
        active: pathname.includes(
          __r(
            'routes:moj-ucet/moje-dodacie-adresy',
            '/moj-ucet/moje-dodacie-adresy',
          ),
        ),
      },
      {
        name: 'Správa účtov',
        url: __r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov'),
        active: pathname.includes(
          __r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov'),
        ),
      },
    ];

    return (
      <Sidebar
        handleLogout={this.logoutUser}
        items={items}
        user={this.props.user}
      />
    );
  };

  public logoutUser = async () => {
    const { dispatch, cartId } = this.props;
    await new Promise((resolve, reject) => {
      dispatch(
        logoutUser(cartId, () => {
          dispatch(fetchCart(true));
        }),
      );
    });
  };
}

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  flex-grow: 1;
  overflow: auto;

  @media only screen and (max-width: 1740px) {
    justify-content: center;
  }
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: ${rem(56)} ${rem(24)};
  justify-content: flex-start;
  align-items: flex-start;

  @media only screen and (max-width: 1020px) {
    flex-flow: column;
    padding: 0 ${rem(16)} ${rem(32)};
    max-width: ${rem(960)};
  }
`;

export const LogoutButton = styled.button`
  text-decoration: none;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  transition: filter 0.2s ease, background-color 0.2s ease;

  &:disabled {
    cursor: not-allowed;

    &:hover {
      filter: brightness(100%);
    }
  }

  &:hover {
    filter: brightness(90%);
  }

  margin: ${rem(56)} auto;
  flex: 0 1 auto;
  border: none;
  border-radius: 0 ${rem(2)} ${rem(2)} 0;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.secondary};
  height: ${rem(48)};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  max-width: ${rem(320)};

  padding: ${rem(15)} ${rem(32)};
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    cartId: cartIdSelector(state),
  };
};

const MyAccountProtected = withRouter(authProtected(MyAccount));
// const MyAccountProtected = MyAccount;

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyAccount' })(MyAccountProtected),
);
