import React from 'react';
import { FlexRowCenterVertical, FlexCol, TextP, SvgIcon } from 'eshop-defaults';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { __, __r } from 'react-i18n';
import { Link } from 'react-router';
import { rem } from 'polished';
import { IconType } from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';
import {
  fetchCart,
  cartIdSelector,
  resetCart,
} from '../../containers/Cart/cartSlice';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';
import { removeCartCookie } from '../../containers/Cart/helpers';

interface Props {
  user: any;
  dispatch: any;
  cartId: string;
}

function HeaderProfile({ user, dispatch, cartId }: Props) {
  const { name, surname, email } = user || {};
  const userName =
    name || surname ? `${name || ''} ${surname || ''}` : `${email}`;

  const handleLogoutUser = async () => {
    await new Promise((resolve, reject) => {
      dispatch(
        logoutUser(cartId, () => {
          dispatch(fetchCart(true));
        }),
      );
    });
  };

  return (
    <Wrapper>
      <Link
        to={
          user
            ? __r('routes:moj-ucet', '/moj-ucet')
            : __r('routes:prihlasenie', '/prihlasenie')
        }
      >
        <SvgIcon icon={IconType.user} alt="user-icon" cursor={'pointer'} />
      </Link>
      <NameLinkWrapper>
        {user ? (
          <>
            <UserName to={__r('routes:moj-ucet', '/moj-ucet')}>
              {userName}
            </UserName>
            {/* <LinkIconWrapper>
              <MyAccountText>{__('Môj účet')}</MyAccountText>
              <IconImg
                width={8}
                height={4}
                src="/images/arrow_triangle_black.svg"
                alt="arrow-down"
              />
            </LinkIconWrapper> */}
            <LinkIconWrapper onClick={handleLogoutUser}>
              <MyAccountText>{__('Odhlásiť sa')}</MyAccountText>
            </LinkIconWrapper>
          </>
        ) : (
          <>
            <UserName to={__r('routes:prihlasenie', '/prihlasenie')}>
              {__('Prihlásiť sa')}
            </UserName>
            <Register to={__r('routes:registracia', '/registracia')}>
              {__('Registrovať sa')}
            </Register>
          </>
        )}
      </NameLinkWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(FlexRowCenterVertical)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-right: ${rem(40)};
  height: ${rem(48)};
  transition: color 0.2s ease;

  &:hover p {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media only screen and (max-width: 1740px) {
    margin-right: ${rem(20)};
  }

  ${({ theme }) => theme.mediab.l925`
    margin-right:${rem(16)};
  `}
`;

const NameLinkWrapper = styled(FlexCol)`
  margin-left: ${rem(16)};

  ${({ theme }) => theme.mediab.l1300`
    display: none;
  `}
`;

const LinkIconWrapper = styled(FlexRowCenterVertical)`
  margin-top: ${rem(4)};
  cursor: pointer;
`;

const UserName = styled(Link)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: none;
  line-height: ${rem(17)};
`;

const Register = styled(UserName)`
  margin-top: ${rem(4)};
`;

const MyAccountText = styled(TextP)`
  font-size: ${rem(14)};
  margin-right: ${rem(8)};
  line-height: ${rem(22)};
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    cartId: cartIdSelector(state),
  };
};

export default connect(mapStateToProps)(HeaderProfile);
