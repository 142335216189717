import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { rem } from 'polished';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { b2bAuthProtected } from '../../utilities/auth';
import { FlexRow, MetaTags, FlexCol, TextP } from 'eshop-defaults';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { Title } from 'eshop-defaults/lib/components/Zemplin/General/Title';
import { Button } from 'eshop-defaults/lib/components/Zemplin/General/Button';
import * as cookie from 'react-cookies';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import API from '../../services/API';
import { USER_COOKIE } from 'react-auth';
import { prop } from '../../utilities';

interface Props {
  user: any;
  dispatch: any;
  children: any;
}

interface State {
  file: any;
  wasImported: boolean;
}

class ImportCart extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(
        setBreadCrumbPath(BreadCrumbType.CUSTOM, {
          name: __('Import objednávky / dopytu'),
          url: __r('routes:import-objednavky', '/import-objednavky'),
        }),
      );
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      file: null,
      wasImported: false,
    };
  }

  public render() {
    const { file, wasImported } = this.state;
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: 'Import objednávky' }} />
          <ImportWrapper>
            <StyledTitle>{__('Import objednávky / dopytu')}</StyledTitle>
            {wasImported ? (
              <>
                <SmallTitle>
                  {__(
                    'Súbor bol vložený, o vytvorení košíka budete informovaný',
                  )}
                </SmallTitle>
                <br />
                <ImportOrderButton onClick={this.handleWasImportedclick}>
                  <ImportOrderTitle>
                    {__('Importovať ďalší súbor')}
                  </ImportOrderTitle>
                </ImportOrderButton>
              </>
            ) : (
              <>
                {' '}
                <InfoText>
                  {__(
                    'Po ukončení importu sa zobrazí hlásenie o výsledku importu so zobrazením položiek, ktoré sa automaticky nepodarilo naimportovať a je potrebné to urobiť ručne. Následne sa produkty vložia do Vášho košíka.',
                  )}
                </InfoText>
                <SmallTitle>{__('Formát CSV/XLSX súboru')}</SmallTitle>
                <ExampleImport>
                  <span> {'objcislo;pocet;jednotka;nazov'}</span>
                  <span> {'objcislo;pocet;jednotka;nazov'}</span>
                </ExampleImport>
                <FileWrapper>
                  <input
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept=".csv,.xlsx"
                    onChange={this.handleFileChange}
                  />
                </FileWrapper>
                <ImportOrderButton
                  disabled={
                    !(
                      !!file &&
                      prop(this.props.user, 'pendingImportId', null) == null
                    )
                  }
                  onClick={this.importCart}
                >
                  <ImportOrderTitle>{__('Vložiť do košíka')}</ImportOrderTitle>
                </ImportOrderButton>
                <ImportOrderButton
                  noMargin={true}
                  disabled={
                    !(
                      !!file &&
                      prop(this.props.user, 'pendingImportId', null) == null
                    )
                  }
                  onClick={this.importCartAsRequest}
                >
                  <ImportOrderTitle>
                    {__('Vložiť do košíka ako dopyt')}
                  </ImportOrderTitle>
                </ImportOrderButton>
              </>
            )}
          </ImportWrapper>
        </Wrapper>
        <InfoBanner />
      </>
    );
  }

  private handleWasImportedclick = () => {
    this.setState({ wasImported: false });
  };

  private handleFileChange = e => {
    if (e.target.files) {
      this.setState({ file: e.target.files[0] });
    }
  };

  public importCart = async () => {
    const { dispatch } = this.props;
    const { file } = this.state;
    if (file) {
      const res = await API.importCart({}, { file });
      if (res) {
        console.log('login user ImportCart importCart');
        dispatch(loginUser(cookie.load(USER_COOKIE)));
        this.setState({ wasImported: true });
      }

      // const intervalId = setInterval(async () => {
      //   const response: any = await API.loadCartImportStatus(res.importId);
      //   console.log({ response });
      //   if (response.importStatus === 'DONE') {
      //     dispatch(replaceCurrentCart(response.cartId));
      //     clearInterval(this.state.intervalId);
      //   }
      // }, 5000);
      // this.setState({ intervalId });
    }
  };

  public importCartAsRequest = async () => {
    const { dispatch } = this.props;
    const { file } = this.state;
    if (file) {
      const res = await API.importCart({ asRequest: '1' }, { file });
      if (res) {
        console.log('login user ImportCart importCartAsRequest');
        dispatch(loginUser(cookie.load(USER_COOKIE)));
        this.setState({ wasImported: true });
      }
    }
  };
}

const ImportWrapper = styled(FlexCol)`
  margin: 0 auto;
  max-width: ${rem(448)};
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
  `}
`;

const StyledTitle = styled(Title)`
  margin-bottom: ${rem(32)};
  margin-top: 0;
`;

const InfoText = styled(TextP)`
  margin-bottom: ${rem(32)};
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(22)};
`;

const SmallTitle = styled(TextP)`
  font-size: ${rem(14)};
  line-height: ${rem(22)};
`;

const ExampleImport = styled(FlexCol)`
  background-color: #99999914;
  border: ${rem(1)} solid #99999952;
  border-radius: ${rem(2)};
  color: ${({ theme }) => theme.colors.textPrimary};
  line-height: ${rem(22)};
  font-weight: 400;
  font-size: ${rem(14)};
  padding: ${rem(16)};
  margin: ${rem(16)} 0 ${rem(24)};
  overflow-x: auto;
  white-space: nowrap;
`;

const ImportOrderTitle = styled.span`
  display: flex;
  align-items: flex-end;
`;

const FileWrapper = styled.div`
  margin-bottom: ${rem(32)};
`;

const ImportOrderButton = styled(Button)<{ noMargin?: boolean }>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(14)};
  padding: ${rem(8)};
  height: ${rem(48)};
  line-height: ${rem(22)};
  text-decoration: none;

  width: 100%;
  display: flex;
  justify-content: center;
  max-height: ${rem(62)};
  transition: filter 0.2s ease;
  margin-top: ${({ noMargin }) => (noMargin ? rem(16) : rem(48))};

  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    background-color: rgb(220, 214, 214);
  }
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: ${rem(56)} ${rem(24)};
  justify-content: flex-start;

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    padding: 0 ${rem(16)} ${rem(32)};
  `}
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

// const ImportCartProtected = authProtected(ImportCart);
const ImportCartProtected = ImportCart;

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ImportCart' })(
    b2bAuthProtected(ImportCartProtected),
  ),
);
