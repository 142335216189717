import { prop, FlexCol, FlexRowCenterVertical, TextP } from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import { replaceCurrentCart } from '../Cart/cartSlice';
import { __, __r } from 'react-i18n';
import { cartRoutes } from '../../utilities/cart';
import { WithRouterProps, withRouter, Link } from 'react-router';
import API from '../../services/API';
import { useInterval } from '../../utilities/hooks';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import * as cookie from 'react-cookies';
import { USER_COOKIE } from 'react-auth';
import Modal from '../../components/_helpers/Default/Modal';
import { Button } from 'eshop-defaults/lib/components/Zemplin/General/Button';

interface Props {
  user: any;
  dispatch: any;
}

function ImportCartModal({ user, dispatch }: Props & WithRouterProps) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [wasSuccess, setWasSuccess] = React.useState(false);
  const [notImported, setNotImported]: any = React.useState(null);

  useInterval(async () => {
    const pendingImportId = prop(user, 'pendingImportId');
    const doneImportId = prop(user, 'doneImportId');
    const doneNotImported = prop(user, 'doneNotImported');

    if (pendingImportId && !modalVisible) {
      const response = await API.loadCartImportStatus(pendingImportId);

      if (response.importStatus === 'DONE') {
        const notImported = prop(response, 'notImported.notImported');

        await API.changeImportStatus(pendingImportId, {}, { status: 'SET' });

        if (response.cartId) {
          setWasSuccess(true);
          await dispatch(replaceCurrentCart(response.cartId!));
        }

        console.log('login user ImportCartModal');
        await dispatch(loginUser(cookie.load(USER_COOKIE)));

        setModalVisible(true);

        if (notImported) {
          setNotImported(notImported);
        }
      }
    } else if (doneImportId) {
      // TODO overit
      await API.changeImportStatus(doneImportId, {}, { status: 'SET' });

      console.log('login user ImportCartModal 2');
      await dispatch(loginUser(cookie.load(USER_COOKIE)));

      setModalVisible(true);
      setNotImported(doneNotImported);
    }
  }, 2000);

  const closeModal = () => {
    // dispatch(loginUser(cookie.load(USER_COOKIE)));
    setModalVisible(false);
  };

  return (
    <>
      {modalVisible && (
        <Modal
          style={{ height: '50%' }}
          size="medium"
          showX={false}
          closeModal={closeModal}
        >
          <Wrapper>
            <Title>
              {wasSuccess
                ? __('Produkty z Vášho importu boli vložené do košíka')
                : __('Nepodarilo sa vytvoriť košík z vloženého súboru')}
            </Title>
            {notImported && (
              <NotImportedWrapper>
                <NotImportedTitle>
                  {__('Tieto produkty nebolo možné vložiť do košíka')}
                </NotImportedTitle>
                {notImported.map(product => (
                  <NotImportedRow>
                    {product.ordernr ? (
                      <NotImportedOrderNr>
                        {`objcislo:${product.ordernr},`}
                      </NotImportedOrderNr>
                    ) : null}
                    {product.count && product.unit ? (
                      <NotImportedCustomOrderNr>
                        {`mnozstvo: ${product.count} ${product.unit},`}
                      </NotImportedCustomOrderNr>
                    ) : null}
                    {product.name ? (
                      <NotImportedName>{`nazov:${product.name},`}</NotImportedName>
                    ) : null}
                  </NotImportedRow>
                ))}
              </NotImportedWrapper>
            )}
            <ButtonsWrapper>
              <ImportOrderButton
                onClick={closeModal}
                to={__r(cartRoutes.INDEX, '/kosik')}
              >
                <ImportOrderTitle>{__('Prejsť do košíka')}</ImportOrderTitle>
              </ImportOrderButton>
              <CloseModalButton onClick={closeModal}>
                <ImportOrderTitle>{__('Zavrieť okno')}</ImportOrderTitle>
              </CloseModalButton>
            </ButtonsWrapper>
          </Wrapper>
        </Modal>
      )}
    </>
  );
}

const Title = styled.h4`
  font-size: ${rem(24)};
  font-weight: 400;
  line-height: ${rem(38)};
  font-family: ${({ theme }) => theme.font.primary};
  margin: 0;
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `}
`;

const NotImportedWrapper = styled(FlexCol)`
  max-height: ${rem(200)};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const NotImportedTitle = styled(Title)`
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.stockCountUnavailable};
`;

const NotImportedRow = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(16)};
`;

const NotImportedText = styled(TextP)`
  margin-left: ${rem(8)};
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const NotImportedOrderNr = styled(NotImportedText)``;
const NotImportedCustomOrderNr = styled(NotImportedText)``;
const NotImportedName = styled(NotImportedText)``;

const Wrapper = styled(FlexCol)`
  height: 100%;
  justify-content: space-between;
  padding: ${rem(24)};
`;

const ButtonsWrapper = styled(FlexRowCenterVertical)`
  justify-content: space-between;
`;

const ImportOrderButton = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(14)};
  padding: ${rem(19)};
  line-height: ${rem(22)};
  text-decoration: none;

  max-width: ${rem(237)};
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: ${rem(62)};
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

const CloseModalButton = styled(Button)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(14)};
  padding: ${rem(8)};
  line-height: ${rem(22)};
  height: ${rem(60)};
  text-decoration: none;

  max-width: ${rem(237)};
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: ${rem(62)};
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

const ImportOrderTitle = styled.span`
  margin-left: ${rem(16)};
  display: flex;
  align-items: flex-end;
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(ImportCartModal));
